<template>
  <v-main>
    <v-container fluid>
      <v-row no-gutters class="titleField text-center pt-1">
        <v-col cols="auto">
          <div class="float-left text-h6">
            <v-btn text color="primary" icon @click="register">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            {{ virtualStation.name }}
          </div>
          <p class="text-h9 font-weight-regular float-left text-left ml-5 mt-1">
            {{ area }} km<sup>2</sup> <br />
            {{ $t("dashboardDetails.lastScene") }}:
            {{ lastSceneDisplay }}
          </p>
        </v-col>
        <div
          v-for="(item, index) in productPlotConfigs"
          :key="index"
          class="d-flex align-start ml-6 mt-3"
        >
          <v-icon
            v-if="
              item.alert &&
                plotInfoLoaded &&
                dataAvailable &&
                productStatistics[item.id].statistics.length
            "
            :color="
              getStatusColor(productStatistics[item.id].current_status.status)
            "
            dense
            >mdi-checkbox-blank-circle
          </v-icon>
          <v-icon
            v-else
            color="
              grey
            "
            dense
            >mdi-checkbox-blank-circle
          </v-icon>
          <div class="pl-1">{{ item.name.split(" ")[0] }}</div>
        </div>
      </v-row>
      <v-row no-gutters>
        <!-- Start Date -->
        <v-col cols="2">
          <v-menu
            v-model="startDateCalenderVisibility"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                class="mx-3"
                v-model="startDate"
              >
                <template v-slot:label>
                  <span style="color: #039BE5">{{
                    $t("monitoring.startDate")
                  }}</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @change="handleStartDateSelect"
              :min="firstSceneDate"
              :max="todaysDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- End Date -->
        <v-col cols="2">
          <v-menu
            v-model="endDateCalenderVisibility"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                class="mx-3"
                v-model="endDate"
              >
                <template v-slot:label>
                  <span style="color: #039BE5">{{
                    $t("monitoring.endDate")
                  }}</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              class="mx-3"
              @change="handleEndDateSelect"
              :min="firstSceneDate"
              :max="todaysDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="1">
          <v-checkbox
            v-model="allDataCheckbox"
            @change="onCheckboxChange"
            :label="$t('monitoring.allData')"
          >
          </v-checkbox>
        </v-col>
        <v-spacer></v-spacer>
        <!--        <download-popup-->
        <!--          v-if="selectedTimestepItem"-->
        <!--          :items-layer="itemsLayer"-->
        <!--          :time-layer="selectedLayerTimestep"-->
        <!--          :product-infos="productPlotConfigs"-->
        <!--          :product-statistics="productStatistics"-->
        <!--          :access-token="accessToken"-->
        <!--          :sensor="selectedTimestepItem.sensor"-->
        <!--          :availableData="availableData"-->
        <!--        >-->
        <!--        </download-popup>-->
      </v-row>
      <v-row no-gutters class="text-center">
        <div v-if="!plotInfoLoaded">
          <v-overlay opacity="0.2" absolute>
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
                size="84"
                v-if="!alertItems.length"
                >{{ $t("order.products") }}
              </v-progress-circular>
              <v-progress-circular
                indeterminate
                color="primary"
                size="84"
                :value="itemCounter"
                v-else-if="itemCounter < itemsLayer.length"
                >{{ itemCounter }}/{{ itemsLayer.length }}
              </v-progress-circular>
              <v-progress-circular
                indeterminate
                color="primary"
                size="84"
                :value="itemCounter"
                v-else-if="itemCounter === itemsLayer.length"
                >Plots
              </v-progress-circular>

              <div>{{ $t("loading") }}</div>
            </div>
          </v-overlay>
        </div>
        <v-col lg="8" v-if="plotInfoLoaded && dataAvailable">
          <v-row>
            <v-col cols="auto" class="ml-2">
              <v-switch
                :disabled="!alertItems.length || this.editMode"
                :label="
                  showThresholds
                    ? $t('baseline.hideThresholds')
                    : $t('baseline.showThresholds')
                "
                color="primary"
                hide-details
                v-model="showThresholds"
                class="mt-0 float-left"
              ></v-switch>
              <v-switch
                :disabled="disableSwitch"
                :label="
                  showInSituData
                    ? $t('inSitu.dashboard.hideInSituData')
                    : $t('inSitu.dashboard.showInSituData')
                "
                color="primary"
                hide-details
                v-model="showInSituData"
                class="mt-0 pl-2 float-left"
              ></v-switch>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                v-model="editMode"
                color="grey"
                class="pb-1 text-lowercase"
                dark
                text
                @click="toggleEdit"
                v-if="!editMode"
              >
                {{ $t("dashboardDetails.editData") }}
              </v-btn>
              <v-btn
                v-else
                color="primary"
                class="pb-1 text-lowercase"
                dark
                text
                @click="toggleEdit"
              >
                {{ $t("cancel") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-responsive
            class="overflow-y-auto"
            :style="{ height: heightPlotGroup + 'px' }"
            width="auto"
          >
            <div style="padding: 8px" v-if="dataAvailable">
              <div v-for="(item, index) in productPlotConfigs" :key="index">
                <v-row
                  no-gutters
                  class="text-center"
                  v-if="!productStatistics[item.id].statistics.length"
                >
                  {{ $t("dashboardDetails.problemsProducts") }}
                </v-row>
                <v-row
                  no-gutters
                  class="text-center"
                  v-if="plotInfoLoaded && !alertItems.length && index === 0"
                >
                  <v-col md="12">
                    <br />
                    -------
                    {{ $t("monitoring.productListNoAlerts") }}
                    -------
                    <br />
                    <br
                  /></v-col>
                </v-row>
                <v-row
                  no-gutters
                  style="background-color: white"
                  align-content="space-around"
                  class="elevation-4"
                >
                  <span class="pl-2" v-if="!inSituDataLoaded">
                    {{ $t("inSitu.dashboard.loading") }}
                  </span>
                  <v-progress-linear
                    color="primary lighten-2"
                    buffer-value="0"
                    stream
                    v-if="!inSituDataLoaded"
                  ></v-progress-linear>
                  <v-col
                    :md="
                      dataToPlots[item.id] &&
                      dataToPlots[item.id].values.length > 0 &&
                      showInSituData
                        ? 6
                        : 8
                    "
                    no-gutters
                    :style="{
                      height: heightPlot + 'px'
                    }"
                  >
                    <v-spacer></v-spacer>
                    <data-plot-dashboard
                      v-if="item.alert && plotInfoLoaded && inSituDataLoaded"
                      :container="item.container"
                      :thresholds="showThresholds"
                      :jsonFetch="productStatistics[item.id]"
                      :titleBase="item.titleBase"
                      :titleXaxis="item.titleXaxis"
                      :titleYaxis="item.titleYaxis"
                      :timeLayer="selectedLayerTimestep"
                      :productLayer="item.id"
                      :virtual-station="virtualStation"
                      :edit-mode="editMode"
                      @toggleReload="reloadValues"
                      @clickedDatetime="setLayerByPlotDatetime"
                      @clickedProductLayer="setLayerByPlotProduct"
                      :inSituData="dataToPlots[item.id]"
                      :showInSituData="showInSituData"
                    />
                    <data-plot-dashboard-no-thresholds
                      v-if="!item.alert && plotInfoLoaded && inSituDataLoaded"
                      :container="item.container"
                      :thresholds="showThresholds"
                      :jsonFetch="productStatistics[item.id]"
                      :titleBase="item.titleBase"
                      :titleXaxis="item.titleXaxis"
                      :titleYaxis="item.titleYaxis"
                      :timeLayer="selectedLayerTimestep"
                      :productLayer="item.id"
                      :virtual-station="virtualStation"
                      :edit-mode="editMode"
                      :reload="reload"
                      @toggleReload="reloadValues"
                      @clickedDatetime="setLayerByPlotDatetime"
                      @clickedProductLayer="setLayerByPlotProduct"
                      :inSituData="dataToPlots[item.id]"
                      :showInSituData="showInSituData"
                    />
                  </v-col>
                  <v-col
                    :md="
                      dataToPlots[item.id] &&
                      dataToPlots[item.id].values.length > 0 &&
                      showInSituData
                        ? 4
                        : 2
                    "
                    no-gutters
                    :style="{
                      height: heightPlot + 'px'
                    }"
                  >
                    <data-box-plot-dashboard
                      v-if="plotInfoLoaded && inSituDataLoaded"
                      :container="item.boxContainer"
                      :thresholds="showThresholds"
                      :jsonFetch="productStatistics[item.id]"
                      :inSituData="dataToPlots[item.id]"
                      :inSituDataContainer="prefix + item.boxContainer"
                      :selectedStation="virtualStation.name"
                      :showInSituData="showInSituData"
                    />
                  </v-col>
                  <v-col
                    md="2"
                    no-gutters
                    :style="{
                      'padding-top': '20px',
                      'padding-right': '10px'
                    }"
                    v-if="plotInfoLoaded"
                  >
                    <status-values
                      :product-statistics="productStatistics"
                      :item="item"
                      :alert="item.alert"
                      :selectedDate="selectedLayerTimestep"
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="text-center"
                  v-if="
                    plotInfoLoaded &&
                      noAlertItems.length &&
                      productPlotConfigs[index].alert &&
                      !productPlotConfigs[index + 1].alert
                  "
                >
                  <v-col md="12">
                    <br />
                    ------- {{ $t("monitoring.productListNoAlerts") }} -------
                    <br />
                    <br
                  /></v-col>
                </v-row>
                <v-row v-else no-gutters class="text-center">
                  <v-col md="12"><br /></v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col>
                    <br />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-responsive>
        </v-col>
        <v-col
          lg="4"
          style="padding-left: 20px;"
          v-if="
            selectedDefaultLayerTop &&
              selectedDefaultLayerBottom &&
              plotInfoLoaded &&
              dataAvailable
          "
        >
          <!--  TODO: Check v-if statements        -->
          <v-row no-gutters class="text-center">
            <v-col
              md="12"
              class="elevation-4"
              :style="{ height: heightMap + 'px' }"
            >
              <dashboard-map-details
                v-if="selectedTimestepItem"
                container="topMap"
                joinView="bottomMap"
                :itemsLayer="itemsLayer"
                :sensor="
                  selectedLayerTimestep !== undefined
                    ? selectedTimestepItem.sensor
                    : 'SENT2'
                "
                :selectedLayerDefault="selectedDefaultLayerTop"
                :timeLayer="selectedLayerTimestep"
                :virtualStation="virtualStation"
                :availableData="availableData"
              ></dashboard-map-details>
            </v-col>
          </v-row>
          <v-row no-gutters class="text-center">
            <v-col md="12"><br /></v-col>
          </v-row>
          <v-row no-gutters class="text-center">
            <v-col
              md="12"
              class="elevation-4"
              :style="{ height: heightMap + 'px' }"
            >
              <dashboard-map-details
                v-if="selectedTimestepItem"
                container="bottomMap"
                joinView="topMap"
                :itemsLayer="itemsLayer"
                :sensor="
                  selectedLayerTimestep !== undefined
                    ? selectedTimestepItem.sensor
                    : 'SENT2'
                "
                :selectedLayerDefault="selectedDefaultLayerBottom"
                :timeLayer="selectedLayerTimestep"
                :virtualStation="virtualStation"
                :clickedProductLayer="clickedProductLayer"
                :availableData="availableData"
              ></dashboard-map-details>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          v-if="!dataAvailable && plotInfoLoaded"
          no-gutters
          style="height: 600px"
        >
          <empty-state-timeseries
            :mode="'plotdata'"
            :start-date="startDate"
            :end-date="endDate"
          ></empty-state-timeseries>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import moment from "moment";
import DataPlotDashboard from "@/core/components/monitoring/DataPlotDashboard.vue";
import DataBoxPlotDashboard from "@/core/components/monitoring/DataBoxPlotDashboard";
import DashboardMapDetails from "@/core/components/monitoring/DashboardMapDetails.vue";
// import DownloadPopup from "@/core/components/monitoring/DownloadPopup.vue";
import { mapActions, mapState } from "vuex";
import geojsonArea from "@mapbox/geojson-area";

import axios from "axios";
import StatusValues from "@/core/components/monitoring/StatusValues.vue";
import DataPlotDashboardNoThresholds from "@/core/components/monitoring/DataPlotDashboardNoThresholds.vue";
import dataManagementMixin from "@/core/mixins/dataManagement.mixin";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import EmptyStateTimeseries from "@/core/components/EmptyStates/EmptyStateTimeseries.vue";
import inSituDataMixin from "@/core/mixins/inSituData.mixin";

export default {
  name: "MonitoringDetails",
  mixins: [dataManagementMixin, virtualStationMixin, inSituDataMixin],
  components: {
    EmptyStateTimeseries,
    DataPlotDashboard,
    DataBoxPlotDashboard,
    DashboardMapDetails,
    StatusValues,
    DataPlotDashboardNoThresholds
  },
  data: () => ({
    virtualStation: { name: "VirtualStation", geometry: null },
    selectedLayerTimestep: null, //"2022-08-14T08:14:40"
    lastSceneDate: "2000-01-01",
    alertItems: [],
    selectedDefaultLayerTop: null,
    selectedDefaultLayerBottom: null,
    showThresholds: false,
    productPlotConfigs: [],
    productStatistics: {},
    show: ["thresholds", "statistics"],
    height: null,
    width: null,
    // heightBaseMap: 380,
    heightBaseMap: 340,
    heightMap: null,
    heightBasePlot: 210,
    heightPlot: 300,
    heightBasePlotGroup: 670,
    heightPlotGroup: null,
    statusInfoShow: false,
    statusFormatDate: "YYYY-MM-DD HH:MM",
    itemsLayer: [],
    itemsFilter: [],
    datetimeSelectionItems: [],
    selectedTimestepItem: null,
    plotInfoLoaded: false,
    area: null,
    vsArea: null,
    noAlertItems: [],
    itemCounter: 0,
    plotCounter: false,
    dataAvailable: false,
    clickedProductLayer: null,
    editMode: false,
    drawSnackbar: false,
    reload: false,
    selectedFilterPeriod: 90,
    availableData: [],
    defaultFilterValue: 90,
    startDateCalenderVisibility: false,
    endDateCalenderVisibility: false,
    startDate: null,
    endDate: null,
    todaysDate: moment().format("YYYY-MM-DD"),
    selectedReferenceDate: moment().format("YYYY-MM-DD"),
    fetchedInSituData: {},
    dataToPlots: {},
    inSituDataLoaded: false,
    prefix: "inSituData_",
    showInSituData: false,
    disableSwitch: false
  }),

  methods: {
    ...mapActions("app", ["showSnackbar", "hideSnackbar"]),
    ...mapActions("raster", ["setRasterLayer", "setLayerTimesteps"]),
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("dashboard", [
      "setFirstSceneDate",
      "setAllData",
      "setSelectedStartDate",
      "setSelectedEndDate"
    ]),

    register() {
      this.$router.go(-1);
    },
    setLayerByPlotDatetime(value) {
      if (value.split("T")[1] == "00:00:00") {
        value = value.split("T")[0];
      }
      this.selectedTimestepItem = this.datetimeSelectionItems.filter(
        item => item.datetime === value
      )[0];
      if (value != undefined) {
        this.selectedLayerTimestep = value;
      }
    },
    setLayerByPlotProduct(productLayerName) {
      this.clickedProductLayer = productLayerName;
    },
    async fetchVirtualStation() {
      const response = await axios.get(
        `userdata/virtual-stations/${this.$route.params.analysisGeometry}`
      );
      this.area =
        Math.round(
          (geojsonArea.geometry(response.data.geometry) / 1000000) * 1000
        ) / 1000; // square kilometer
      this.virtualStation = response.data;
    },

    async fetchLayers() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.$route.params.regionId}&token=${this.accessToken}`
      );

      if (response.data.length > 0) {
        let filteredProducts = null;
        if (!this.isAdmin && !this.seeQUT) {
          filteredProducts = response.data.filter(
            layer => layer.product !== "qut"
          );
        } else {
          filteredProducts = response.data;
        }
        let data = filteredProducts.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        if (this.$appConfig.keycloakClient == "coasts") {
          data = data.filter(item => !item.product.includes("change"));
        }
        this.setRasterLayer(data);
        this.itemsLayer = data;
      }
    },

    async fetchTimesteps(daysBack) {
      const allLayerIds = this.itemsLayer.map(item => item.layerId);

      this.availableData = [];

      const requests = allLayerIds.map(layerId =>
        this.$rastless
          .get(
            `/layers/${layerId}/steps?details=false&token=${this.accessToken}`
          )
          .then(async response => {
            let availableScenes = response.data;
            return {
              product: layerId,
              availableScenes: availableScenes
            };
          })
      );

      const results = await Promise.allSettled(requests);

      const successfulData = results
        .filter(result => result.status === "fulfilled")
        .map(result => result.value);

      this.availableData.push(...successfulData);

      const errors = results
        .filter(result => result.status === "rejected")
        .map(result => result.reason);

      errors.forEach(error => {
        this.showSnackbar({
          show: true,
          message:
            this.$t("dashboardDetails.fetchingTimestepsErrorMsg") + `${error}`,
          color: "error"
        });
      });

      const flattenedResponses = [].concat(
        ...this.availableData.map(data => data.availableScenes)
      );

      /*
      Explanation: how the const uniqueResponses = flattenedResponses.filter(timestep =>... works:

      for each timestep this function checks if its datetime is already present in the uniqueDatetimes

      if date time is present in uniqueDatetimes function returns false what means that this timestep should be excluded from the resulting uniqueResponses array.
      if it's not present , the function adds this datetime to uniqueDatetimes and returns true , what means that this timestep should be included in the resulting uniqueResponses array.
*/

      const uniqueDatetimes = new Set();
      const uniqueResponses = flattenedResponses.filter(timestep => {
        if (uniqueDatetimes.has(timestep.datetime)) {
          return false;
        } else {
          uniqueDatetimes.add(timestep.datetime);
          return true;
        }
      });

      const data = uniqueResponses.sort(
        (a, b) => new Date(b.datetime) - new Date(a.datetime)
      );
      this.setFirstSceneDate(
        data[data.length - 1].datetime.replace("T", " ").split(" ")[0]
      );

      let startDate, endDate;

      if (!this.startDate || !this.endDate) {
        endDate = moment().format("YYYY-MM-DD");
        startDate = moment()
          .subtract(daysBack, "d")
          .format("YYYY-MM-DD");
      } else {
        endDate = this.endDate;
        startDate = this.startDate;
      }

      this.datetimeSelectionItems = data
        .filter(timestep => timestep.temporalResolution === "daily")
        .filter(item => {
          const datetime = item.datetime.replace("T", " ");
          const datePart = datetime.split(" ")[0];
          return datePart >= startDate && datePart <= endDate;
        });
      if ("timestep" in this.$route.query) {
        this.datetimeSelectionItems.forEach(date => {
          if (date.datetime === this.$route.query.timestep) {
            this.selectedTimestepItem = date;
            this.selectedLayerTimestep = date.datetime;
          } else {
            this.selectedTimestepItem = this.datetimeSelectionItems[0];
            this.selectedLayerTimestep = this.datetimeSelectionItems[0].datetime;
          }
        });
      } else {
        this.selectedTimestepItem = this.datetimeSelectionItems[0];
        this.selectedLayerTimestep = this.datetimeSelectionItems[0].datetime;
      }
      this.setLayerTimesteps(this.datetimeSelectionItems);

      this.lastSceneDate =
        this.datetimeSelectionItems.length > 0
          ? this.datetimeSelectionItems[0].datetime
          : "-";

      if (this.datetimeSelectionItems.length > 0) {
        this.setFirstSceneDate(
          data[data.length - 1].datetime.replace("T", " ").split(" ")[0]
        );
      }
    },

    async fetchAlertItems() {
      const response = await axios.get(
        `dashboard/virtual-stations/${this.$route.params.analysisGeometry}/alerts`
      );
      this.alertItems = response.data;
      return this.alertItems;
    },

    async getPlotConfigs() {
      await this.alertItems.forEach(alert => {
        this.getPlotConfigForLayer(alert.layer, true);
      });
      this.noAlertItems = this.noAlertItems.filter(
        product => product.product !== "rgb"
      );
      await this.noAlertItems.forEach(item => {
        this.getPlotConfigForLayer(item.layerId, false);
      });
    },

    getPlotConfigForLayer(layerId, alert = false) {
      const layer = this.itemsLayer.find(item => {
        return item.layerId === layerId;
      });
      this.productPlotConfigs.push({
        id: layer.layerId,
        name: `${layer.product.toUpperCase()} - ${layer.title}`,
        container: `plot${layer.layerId}`,
        boxContainer: `boxPlot${layer.layerId}`,
        titleBase: `${layer.product.toUpperCase()} - ${layer.title}`,
        legendName: "Test",
        titleXaxis: "",
        titleYaxis: `${layer.title} [${layer.unit}]`,
        productUnits: layer.unit,
        alert: alert
      });
    },

    async changeFilterDays(param) {
      if (param >= 0) {
        this.plotInfoLoaded = false;
        this.selectedFilterPeriod = param;
        await this.fetchTimesteps(param);
        this.fetchPlotData(param);
      } else {
        this.showSnackbar({
          show: true,
          message: this.$t("monitoring.negativeDateRange"),
          color: "error"
        });
      }
    },

    changeSelectedLayerTimestep(value) {
      const rastlessDatetime = value.replace(" ", "T");
      this.selectedTimestepItem = this.datetimeSelectionItems.filter(
        item => item.datetime === rastlessDatetime
      )[0];
      this.selectedLayerTimestep = rastlessDatetime;
    },
    fetchPlotData(daysBack) {
      this.statusInfoShow = false;
      let statistics = [];
      this.alertItems.forEach(alert => {
        this.itemCounter += 1;
        statistics.push(this.fetchProductStatistic(alert, daysBack));
      });
      this.noAlertItems.forEach(item => {
        this.itemCounter += 1;
        statistics.push(this.fetchNoAlertProductStatistic(item, daysBack));
      });

      Promise.all(statistics).then(() => {
        this.plotInfoLoaded = true;

        let askStatistics = [];
        this.productPlotConfigs.forEach(alert => {
          if (this.productStatistics[alert.id].statistics.length) {
            askStatistics.push(true);
          } else {
            askStatistics.push(false);
          }
        });

        // check data available for selected time period
        if (askStatistics.includes(true)) {
          this.dataAvailable = true;
        } else {
          this.dataAvailable = false;
        }
      });
    },

    filterNoAlertItems() {
      let noAlerts = new Set();
      if (this.itemsLayer.length && this.alertItems.length) {
        this.itemsLayer.forEach(item => {
          this.alertItems.some(alert => {
            if (item.layerId === alert.layer) {
              noAlerts.add(item);
            }
          });
        });
        noAlerts = Array.from(noAlerts);
        this.itemsLayer.forEach(alert => {
          if (!noAlerts.includes(alert)) {
            this.noAlertItems.push(alert);
          }
        });
      } else if (this.itemsLayer.length && !this.alertItems.length) {
        this.noAlertItems = this.itemsLayer;
      } else {
        this.noAlertItems = [];
      }
    },

    async fetchNoAlertProductStatistic(layer, daysback) {
      let response = {};
      const steps = this.availableData.filter(
        data => data.product == layer.layerId
      );
      if (daysback != 10000) {
        response.data = await this.fetchTimeseries(
          layer,
          this.virtualStation,
          steps,
          this.startDate,
          this.endDate
        );
      } else {
        response.data = await this.fetchTimeseries(
          layer,
          this.virtualStation,
          steps
        );
      }

      if (response.data.datetime?.length) {
        response.data = await this.filterOutMarkedSteps(
          response.data,
          this.$route.params.regionId,
          layer.layerId,
          this.virtualStation.id
        );
      }
      const statistics = [];
      response.data.datetime.forEach((date, index, array) => {
        array.forEach((datetime, index) => {
          statistics[index] = {
            scene_date: datetime,
            status: null,
            value: Object.values(response.data.data)[0][index]
          };
        });
      });
      this.productStatistics[layer.layerId] = {
        statistics: statistics,
        current_status: {},
        previous_status: {},
        thresholds: {}
      };
    },

    async fetchProductStatistic(alert, daysBack) {
      const response = await axios.get(
        `dashboard/alerts/${alert.id}/statistics/?days=${daysBack}&reference_date=${this.selectedEndDate}`
      );
      if (response.data.statistics?.length) {
        response.data = await this.filterOutMarkedStepsForAlerts(
          response.data,
          this.$route.params.regionId,
          alert.layer,
          this.virtualStation.id
        );
      }
      this.productStatistics[alert.layer] = response.data;
      this.productStatistics[alert.layer][
        "thresholds"
      ] = await this.fetchThresholds(alert.id);
    },

    async fetchThresholds(alertId) {
      const response = await axios.get(
        `dashboard/alerts/${alertId}/thresholds`
      );
      return response.data;
    },

    setDefaultRastlessLayer() {
      if (this.itemsLayer) {
        const layer = this.itemsLayer.find(item => {
          return item.product === "rgb";
        });
        if (layer) {
          this.selectedDefaultLayerTop = layer.layerId;
        } else {
          this.selectedDefaultLayerTop = "satellite";
        }
      }
      if (this.alertItems.length) {
        this.selectedDefaultLayerBottom = this.alertItems[0].layer;
      } else {
        this.selectedDefaultLayerBottom = this.itemsLayer[0].layerId;
      }
    },

    getEventDimensions(e) {
      const { innerHeight, innerWidth } = e.currentTarget;
      this.height = innerHeight;
      this.Width = innerWidth;
      this.mapDimensions();
      this.plotDimensions();
      this.plotGroupDimensions();
      return;
    },

    mapDimensions() {
      let design =
        this.height <= 500
          ? 1300
          : this.height <= 600
          ? 1250
          : this.height <= 700
          ? 1120
          : this.height <= 800
          ? 1065
          : this.height <= 900
          ? 1015
          : this.height <= 1264
          ? 1000
          : 1000;
      this.heightMap = (this.heightBaseMap * this.height) / design;
    },

    plotDimensions() {
      if (this.height >= 800) {
        this.heightPlot = (this.heightBasePlot * this.height) / 960;
      } else {
        this.heightPlot = 180;
      }
    },

    plotGroupDimensions() {
      let design =
        this.height <= 500
          ? 1220
          : this.height <= 600
          ? 1180
          : this.height <= 700
          ? 1095
          : this.height <= 800
          ? 1055
          : this.height <= 900
          ? 1020
          : this.height <= 1264
          ? 1000
          : 710;
      this.heightPlotGroup = (this.heightBasePlotGroup * this.height) / design;
    },
    getStatusColor(status) {
      if (status === "warning") {
        return "orange";
      } else if (status === "alert") {
        return "#f24726";
      } else {
        return "#8fd14f";
      }
    },
    toggleEdit() {
      if (this.showThresholds) {
        this.showThresholds = false;
      }
      if (this.showInSituData) {
        this.showInSituData = false;
      }
      if (this.editMode) {
        if (this.isDataAvailable) {
          this.disableSwitch = false;
        }
        this.editMode = false;
        this.hideSnackbar();
      } else {
        this.disableSwitch = true;
        this.editMode = true;
        this.showSnackbar({
          show: true,
          message: this.$t("baseline.selectPointsDashboard"),
          color: "primary",
          timeout: -1
        });
      }
    },
    async reloadValues() {
      this.plotInfoLoaded = false;
      await this.fetchPlotData(this.selectedFilterPeriod);
      if (this.reload) {
        this.reload = false;
      } else {
        this.reload = true;
      }
    },

    onCheckboxChange(value) {
      this.setAllData(value);

      if (this.allData) {
        this.selectedDateRange = 10000;
        this.startDate = this.firstSceneDate;
        this.endDate = this.todaysDate;
        this.setSelectedEndDate(this.selectedReferenceDate);
        this.changeFilterDays(this.selectedDateRange).then(() => {
          this.startDate = this.firstSceneDate;
          this.handleIncomingDates(
            this.startDate,
            this.endDate,
            this.fetchedInSituData
          );
        });
      } else {
        this.selectedDateRange = 90;
        this.endDate = this.selectedReferenceDate;
        this.setSelectedEndDate(this.selectedReferenceDate);
        this.startDate = moment()
          .subtract(90, "days")
          .format("YYYY-MM-DD");
        this.changeFilterDays(this.selectedDateRange);

        this.handleIncomingDates(
          this.startDate,
          this.endDate,
          this.fetchedInSituData
        );
      }
    },
    handleStartDateSelect(date) {
      this.setSelectedStartDate(date);
      this.startDate = date;
      this.startDateCalenderVisibility = false;
      this.setAllData(false);
      this.handleDateRange(date, this.endDate);

      this.handleIncomingDates(date, this.endDate, this.fetchedInSituData);
    },
    handleEndDateSelect(date) {
      this.setSelectedEndDate(date);
      this.endDate = date;
      this.endDateCalenderVisibility = false;
      this.setAllData(false);
      this.handleDateRange(this.startDate, date);
      this.handleIncomingDates(this.startDate, date, this.fetchedInSituData);
    },
    handleDateRange(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      if (this.startDate && this.endDate) {
        this.selectedDateRange = moment(endDate).diff(
          moment(startDate),
          "days"
        );
        this.changeFilterDays(this.selectedDateRange);

        this.handleIncomingDates(
          this.startDate,
          this.endDate,
          this.fetchedInSituData
        );
      }
    },
    filterInSituDataByDate(rastless_layer_id, date, isStartDate) {
      const valuesIndexes = rastless_layer_id.date_times.reduce(
        (indices, currentDate, index) => {
          if (isStartDate ? currentDate >= date : currentDate <= date) {
            indices.push(index);
          }
          return indices;
        },
        []
      );
      return {
        date_times: rastless_layer_id.date_times.filter((_, index) =>
          valuesIndexes.includes(index)
        ),
        values: rastless_layer_id.values.filter((_, index) =>
          valuesIndexes.includes(index)
        )
      };
    },
    handleIncomingDates(startDate, endDate, data) {
      const isInDateRange = d_t => {
        return d_t >= startDate && d_t <= endDate;
      };
      let filteredData = {};
      Object.keys(data).forEach(setID => {
        const dates = data[setID].date_times;
        const values = data[setID].values;

        const filteredIndices = dates
          .map((date, index) => (isInDateRange(date) ? index : -1))
          .filter(index => index !== -1);
        filteredData[setID] = {
          date_times: filteredIndices.map(index => dates[index]),
          values: filteredIndices.map(index => values[index])
        };
      });
      this.dataToPlots = filteredData;
      this.inSituDataLoaded = true;

      if (this.isDataAvailable) {
        this.disableSwitch = false;
        if (this.editMode) {
          this.disableSwitch = true;
        }
      } else {
        this.disableSwitch = true;
      }
    }
  },
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapState("dashboard", [
      "firstSceneDate",
      "layersWithRegionThreshold",
      "allData",
      "selectedStartDate",
      "selectedEndDate"
    ]),
    ...mapState("raster", ["rasterLayer"]),
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    },
    seeQUT() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("lfurlp");
    },
    lastSceneDisplay() {
      return this.lastSceneDate === "-"
        ? "-"
        : moment(this.lastSceneDate).calendar(null, {
            sameDay: "[Today] HH:mm",
            lastDay: "[Yesterday]",
            lastWeek: "[Last] dddd",
            sameElse: "YYYY-MM-DD"
          });
    },
    allDataCheckbox: {
      get() {
        return this.allData;
      },
      set(value) {
        this.setAllData(value);
      }
    },
    isDataAvailable() {
      return Object.values(this.dataToPlots).some(
        element => element.date_times.length > 0
      );
    }
  },
  created() {
    window.addEventListener("resize", this.getEventDimensions);
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    this.itemsFilter = [
      {
        title: this.$t("dashboardDetails.periodFilterChoices.all"),
        value: 10000
      },
      {
        title: this.$t("dashboardDetails.periodFilterChoices.last3Months"),
        value: 90
      },
      {
        title: this.$t("dashboardDetails.periodFilterChoices.lastMonth"),
        value: 30
      },
      {
        title: this.$t("dashboardDetails.periodFilterChoices.lastWeek"),
        value: 7
      }
    ];
    this.mapDimensions();
    this.plotDimensions();
    this.plotGroupDimensions();
    this.fetchVirtualStation();
    this.getOrFetchAccessToken().then(() => {
      this.fetchLayers().then(() => {
        this.fetchAlertItems().then(async () => {
          this.filterNoAlertItems();
          this.setDefaultRastlessLayer();
          if (this.allData) {
            this.selectedDateRange = 10000;
            this.startDate = this.firstSceneDate;
            this.endDate = this.todaysDate;
            this.setSelectedEndDate(moment().format("YYYY-MM-DD"));
            this.setSelectedStartDate(this.firstSceneDate);
          } else if (this.selectedStartDate && this.selectedEndDate) {
            this.selectedDateRange = moment(this.selectedEndDate).diff(
              moment(this.selectedStartDate),
              "days"
            );
            this.startDate = this.selectedStartDate;
            this.endDate = this.selectedEndDate;
          } else {
            this.selectedDateRange = 90;
            this.startDate = moment()
              .subtract(90, "days")
              .format("YYYY-MM-DD");
            this.endDate = this.selectedReferenceDate;
            this.setSelectedStartDate(this.startDate);
            this.setSelectedEndDate(this.endDate);
          }

          this.fetchTimesteps(this.selectedDateRange).then(() => {
            this.getPlotConfigs().then(() => {
              this.fetchPlotData(this.selectedDateRange);
            });
          });

          // IN SITU DATA
          const filteredItemsLayer = this.itemsLayer.filter(
            item => item.product !== "rgb"
          );

          const fetchInSituStationsData = await Promise.allSettled(
            filteredItemsLayer.map(item =>
              this.fetchInSituStationsData(this.virtualStation.id, item.layerId)
            )
          );
          const allInSituDataResults = [];
          fetchInSituStationsData.forEach(promiseResult => {
            if (promiseResult.status === "fulfilled") {
              allInSituDataResults.push(promiseResult.value);
            } else {
              this.showSnackbar({
                show: true,
                message: "Error in fetching in situ data",
                color: "error"
              });
            }
            if (
              allInSituDataResults.length === filteredItemsLayer.length &&
              this.selectedStartDate &&
              this.selectedEndDate
            ) {
              const filteredResults = allInSituDataResults.filter(
                result => result.rastless_layer_id !== ""
              );
              filteredResults.forEach(result => {
                this.fetchedInSituData[result.rastless_layer_id] = {
                  date_times: result.date_times,
                  names: result.names,
                  values: result.values
                };
              });
              this.dataToPlots = this.fetchedInSituData;
              this.handleIncomingDates(
                this.startDate,
                this.endDate,
                this.dataToPlots
              );
            } else {
              this.inSituDataLoaded = true;
            }
          });
        });
      });
    });
  }
};
</script>

<style scoped></style>
