import axios from "axios";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Url from "@/core/services/url.services";

let inSituDataMixin = {
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("vector", ["fetchVectorLayer"]),
    ...mapActions("inSitu", [
      "updateDataForResultView",
      "updateDataForResultViewHeaders",
      "clearDataForResultViewHeaders",
      "clearDataForResultView"
    ]),
    ...mapActions("app", ["showSnackbar"]),

    assignAndCompare(data) {
      const responseAssociations = {};
      data.responseAssociations.forEach(assoc => {
        if (assoc.rastless_layer_id !== null) {
          responseAssociations[assoc.insitu_parameter_id] =
            assoc.insitu_parameter_name + "ASSOCIATED";
        } else {
          responseAssociations[assoc.insitu_parameter_id] =
            assoc.insitu_parameter_name + "NON-ASSOCIATED";
        }
      });

      const results = data.tableContent.map(item => {
        const product = item.associatedProduct;
        const assignedData = {
          associatedStationName: item.associatedStationName,
          associatedStationID: item.associatedStationID,
          inSituStationID: item.inSituStationID,
          inSituStationName: item.inSituStationName
        };

        for (const key in product) {
          if (Object.prototype.hasOwnProperty.call(product, key)) {
            const insituParameterName = responseAssociations[key];
            if (insituParameterName) {
              assignedData[insituParameterName] = product[key];
            }
          }
        }
        return assignedData;
      });
      return results;
    },
    async fetchInSituStationsAndAssociations() {
      this.clearDataForResultViewHeaders();
      this.clearDataForResultView();

      const client = Vue.prototype.$appConfig.keycloakClient;

      const promisesInSituStations = this.vectorLayer.map(async item => {
        try {
          // Fetch data from the insitu stations endpoint
          const responseStations = await axios.get(
            `/vector/${client}/region/${this.activeRegion.id}/layers/${item.id}/insitu-stations`
          );

          // Fetch data from the associations endpoint
          const responseAssociations = await axios.get(
            `/vector/${client}/region/${this.activeRegion.id}/layers/${item.id}/associations`
          );

          // Combine data from both responses
          const tableContent = responseStations.data.map(element => ({
            inSituStationName: element.name,
            inSituStationID: element.id,
            layerOwner: item.owner,
            associatedStationID: element.virtual_station_id,
            associatedStationName: element.virtual_station_name
              ? element.virtual_station_name
              : this.$t("inSitu.headers.notAssociated"),
            associatedProduct: element.product_value_count
          }));

          return {
            id: item.id,
            name: item.name,
            tableContent: tableContent,
            responseAssociations: responseAssociations.data
          };
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("downloadView.fetchingSingleId") + `${error}`,
            color: "error"
          });
          return null;
        }
      });

      await Promise.allSettled(promisesInSituStations).then(results => {
        const associatedHeadersSet = new Set();
        const notAssociatedHeadersSet = new Set();

        results.forEach(result => {
          if (result.status === "fulfilled") {
            const assignedDataResults = this.assignAndCompare(result.value);

            // Update data for each result view
            this.updateDataForResultView({
              dataSetID: result.value.id,
              dataSetName: result.value.name,
              dataForContent: result.value.tableContent,
              result: assignedDataResults
            });

            result.value.responseAssociations.forEach(header => {
              if (header.rastless_layer_id !== null) {
                associatedHeadersSet.add({
                  headerID: header.insitu_parameter_id,
                  headerName: header.insitu_parameter_name + "ASSOCIATED"
                });
              } else {
                notAssociatedHeadersSet.add({
                  headerID: header.insitu_parameter_id,
                  headerName: header.insitu_parameter_name + "NON-ASSOCIATED"
                });
              }
            });
          } else {
            this.showSnackbar({
              show: true,
              message: `${result.reason}`,
              color: "error"
            });
          }
        });

        const associatedHeaders = Array.from(associatedHeadersSet);
        const notAssociatedHeaders = Array.from(notAssociatedHeadersSet);

        this.updateDataForResultViewHeaders({
          associatedHeaders,
          notAssociatedHeaders
        });
      });
    },

    //for baseline plots

    async fetchCorrespondingInSituStations(virtual_station_id) {
      //fetch all in-situ-stations which correspond to selected virtual station
      const client = this.$appConfig.keycloakClient;

      try {
        const response = await axios.get(
          `/vector/${client}/virtual-station/${virtual_station_id}/layer_geometries/f4e26483-6731-4e41-80cd-4d8921665a7`
        );
        return response.data;
      } catch (error) {
        this.showSnackbar({
          show: true,
          message: error.message,
          color: "error"
        });
      }
    },
    async fetchInSituStationsData(virtual_station_id, product_id) {
      const inSituStationData = await this.fetchCorrespondingInSituStations(
        virtual_station_id
      );
      if (inSituStationData) {
        let filteredResults = [];

        inSituStationData.forEach(station => {
          let matchingProperties = station.corresponding_vector_layer.properties.filter(
            property => property.rastless_layer_id === product_id
          );
          matchingProperties.forEach(matchedProperty => {
            filteredResults.push({
              virtualStationID: station.id,
              parameter: matchedProperty.id,
              start_date: station.corresponding_vector_layer.date_range.min,
              end_date: station.corresponding_vector_layer.date_range.max,
              inSituStation_name: station.name,
              rastless_layer_id: matchedProperty.rastless_layer_id
            });
          });
        });

        const promises = filteredResults.map(result =>
          this.getInSituDataValues(
            result.virtualStationID,
            result.parameter,
            result.start_date,
            result.end_date,
            result.inSituStation_name,
            result.rastless_layer_id
          )
        );
        try {
          const results = await Promise.allSettled(promises);
          const combinedData = results.reduce(
            (acc, result) => {
              if (result.status === "fulfilled" && result.value) {
                acc.date_times = acc.date_times.concat(result.value.date_times);
                acc.values = acc.values.concat(result.value.values);
                acc.names = acc.names.concat(result.value.names);
                if (!acc.rastless_layer_id) {
                  acc.rastless_layer_id = result.value.rastless_layer_id;
                }
              }
              return acc;
            },
            { date_times: [], values: [], names: [], rastless_layer_id: "" }
          );
          return combinedData;
        } catch (error) {
          console.log("rerror", error);
        }
      }
    },
    async getInSituDataValues(
      inSituStation_id,
      parameter,
      startDate,
      endDate,
      in_situ_station_name,
      rastless_layer_id
    ) {
      const url = Url.vectorTimeseries({
        geometryId: inSituStation_id,
        property: parameter,
        startDate: startDate,
        endDate: endDate
      });
      try {
        const response = await axios.get(url);

        const date_times = response.data.map(entry => entry.datetime);
        const values = response.data.map(entry => entry.properties[parameter]);

        return {
          date_times: date_times,
          values: values,
          names: in_situ_station_name,
          rastless_layer_id: rastless_layer_id
        };
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  },

  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("vector", ["vectorLayer", "activeVectorLayer"]),
    ...mapState("inSitu", ["inSituStations"])
  }
};

export default inSituDataMixin;
